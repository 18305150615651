@font-face {
    font-family: 'HelveticaNow';
    src: url('../fonts/subset-HelveticaNeueLTStd-HvIt.woff2') format('woff2'),
        url('../fonts/subset-HelveticaNeueLTStd-HvIt.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNow';
    src: url('../fonts/subset-HelveticaNowText-Regular.woff2') format('woff2'),
        url('../fonts/subset-HelveticaNowText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNow';
    src: url('../fonts/subset-HelveticaNowText-Light.woff2') format('woff2'),
        url('../fonts/subset-HelveticaNowText-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

