@import "fonts";

html, body {
    margin: 0;
    padding: 0;
    font-family: "HelveticaNow", "Helvetica Neue", Helvetica, Arial, sans-serif;
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
}

@keyframes hue {
    0% {
        background-color: #fff;
        animation-timing-function: ease-out;
    }
    10% {
        background-color: #f00;
        filter: hue-rotate(0deg);
    }
    90% {
        background-color: #f00;
        filter: hue-rotate(360deg);
        animation-timing-function: ease-out;
    }
    99% {
        filter: hue-rotate(360deg);
        background-color: #fff;
        animation-timing-function: step-end;
    }
    100% {
        filter: hue-rotate(0deg);
        background-color: #fff;
    }
}

@keyframes progress {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.site-title {
    width: 90vw;
    height: 29vw;
    max-width: 60rem;
    color: transparent;
    font-size: 0;
    mask-image: url('../images/bunt-logo.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    background: #fff;
    animation: hue 7s linear infinite;
}

.main-nav {
    font-weight: 900;
    font-size: 1.5rem;
    font-style: italic;
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    max-width: 1000px;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    z-index: 1;

    @media(max-width: 1080px) {
        right: 2rem;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
        position: relative;
        display: inline-block;
        padding: 0 .25em;

        &::after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            clip-path: inset(0 100% 0 0);
            transition: all .3s ease-out;
            backdrop-filter: invert(100%);
        }

        &:hover {
            &::after {
                clip-path: inset(0 0 0 0);
            }
        }
    }
}

.customer-logos {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

    img {
        width: 100%;
        object-fit: contain;
        object-position: center;
    }
}

.content {
    max-width: 1000px;
    width: 100%;
    position: relative;
    margin: 0 auto;
    padding: 6rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;

    &--first {
        padding-top: 12rem;
    }

    &--hero {
        min-height: 100vh;
    }

    &--twocol {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        align-items: flex-start;

        @media(max-width: 800px) {
            display: flex;
            flex-direction: column;
        }
    }

    &--footer {
        display: grid;
        grid-template-columns: 11.25rem 1fr 11.25rem;
        gap: 2rem;

        @media(max-width: 680px) {
            display: flex;
            flex-direction: column;
            text-align: center;
        }
    }
}

section {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section {
    &--black {
        color: #fff;
        background-color: #000;
    }

    &__title {
        font-size: 6rem;
        line-height: 1;
        font-weight: 900;
        font-style: italic;
        margin: 0;

        @media(max-width: 520px) {
            font-size: 3.75rem;
            margin-bottom: .375rem;
        }
    }
    &__subtitle {
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: 900;
        font-style: italic;
    }
    &__text {
        font-size: 1.125rem;
        font-weight: 300;
        line-height: 1.5;
        margin-top: 13.5rem;

        &--closer {
            margin-top: 6rem;
        }

        @media(max-width: 800px) {
            margin-top: 0rem;
        }

        p {
            margin: 0 0 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__form {
        width: 100%;
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
    margin-top: 13.5rem;

    @media(max-width: 800px) {
        margin-top: 0rem;
    }

    &__label {
        border-bottom: 1px solid currentColor;
        display: flex;
        flex-direction: column-reverse;
        padding-top: .75rem;

        &__text {
            font-size: 1.5rem;
            line-height: 1.5rem;
            font-weight: 900;
            font-style: italic;
            transform-origin: left bottom;
            transition: all .3s ease-out;
        }

        input, textarea {
            border: 0;
            appearance: none;
            width: 100%;
            font-size: 1.125rem;
            line-height: 1.5rem;
            background: transparent;
            color: currentColor;
            font-family: "HelveticaNow", "Helvetica Neue", Helvetica, Arial, sans-serif;

            &:focus {
                outline: 0;

                & + * {
                    transform: scale(.875) translateY(-50%);
                }
            }
        }

        textarea {
            resize: vertical;
        }
    }

    input[type="submit"] {
        width: auto;
        margin-left: auto;
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: 900;
        font-style: italic;
        appearance: none;
        border: 0;
        background: transparent;
        cursor: pointer;
    }

    &__success {
        opacity: 0;
        display: none;
        transition: all .3s ease-in-out;
        color: #0c0;
        background: #efe;
        padding: 1.5rem 1rem;
        text-align: center;

        &--show {
            display: block;
            opacity: 1;
        }
    }

    &__error {
        opacity: 0;
        display: none;
        transition: all .3s ease-in-out;
        color: #c00;
        background: #fee;
        padding: 1.5rem 1rem;
        text-align: center;

        &--show {
            display: block;
            opacity: 1;
        }
    }

    &__submit {
        position: relative;
        display: inline-block;
        align-self: flex-end;

        &::after {
            content: '';
            display: block;
            width: 1rem;
            height: 1rem;
            border: 4px solid #000;
            border-top-color: transparent;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            right: -2rem;
            margin-top: -.5rem;
            animation: progress .5s both infinite linear;
            transition: opacity .3s ease-in-out;
            opacity: 0;
        }

        &--progress {
            &::after {
                opacity: 1;
            }
        }
    }
}

footer {
    background-color: #000;
    color: rgba(#fff, .5);
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 3rem 0;

    a {
        color: inherit;
        text-decoration: none;
        transition: all .3s ease-out;

        &:hover {
            color: #fff;
        }
    }
}

.footer {
    &__nav {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: .75rem;

        &__title {
            font-weight: 900;
            font-style: italic;
            font-size: 1.5rem;
            line-height: 1.875rem;
        }
    }

    &__logo {
        display: flex;
        justify-content: center;

        @media(max-width: 680px) {
            order: -1;
        }

        img {
            width: 9rem;
            height: auto;
            margin: 0 auto;
        }
    }
}