@font-face {
  font-family: HelveticaNow;
  src: url("subset-HelveticaNeueLTStd-HvIt.8670a59e.woff2") format("woff2"), url("subset-HelveticaNeueLTStd-HvIt.274a0d5e.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src: url("subset-HelveticaNowText-Regular.a6c9358b.woff2") format("woff2"), url("subset-HelveticaNowText-Regular.72313395.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: HelveticaNow;
  src: url("subset-HelveticaNowText-Light.fea88f24.woff2") format("woff2"), url("subset-HelveticaNowText-Light.6e8a2c16.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

html, body {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-family: HelveticaNow, Helvetica Neue, Helvetica, Arial, sans-serif;
}

* {
  box-sizing: border-box;
}

@keyframes hue {
  0% {
    background-color: #fff;
    animation-timing-function: ease-out;
  }

  10% {
    filter: hue-rotate();
    background-color: red;
  }

  90% {
    filter: hue-rotate(360deg);
    background-color: red;
    animation-timing-function: ease-out;
  }

  99% {
    filter: hue-rotate(360deg);
    background-color: #fff;
    animation-timing-function: step-end;
  }

  100% {
    filter: hue-rotate();
    background-color: #fff;
  }
}

@keyframes progress {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.site-title {
  color: #0000;
  background: #fff;
  width: 90vw;
  max-width: 60rem;
  height: 29vw;
  font-size: 0;
  animation: 7s linear infinite hue;
  -webkit-mask-image: url("bunt-logo.b8162d50.svg");
  mask-image: url("bunt-logo.b8162d50.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.main-nav {
  z-index: 1;
  justify-content: flex-end;
  max-width: 1000px;
  margin: 0 auto;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 900;
  display: flex;
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
}

@media (width <= 1080px) {
  .main-nav {
    right: 2rem;
  }
}

.main-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav a {
  color: inherit;
  padding: 0 .25em;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.main-nav a:after {
  content: "";
  clip-path: inset(0 100% 0 0);
  -webkit-backdrop-filter: invert();
  backdrop-filter: invert();
  background-color: #0000;
  transition: all .3s ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

.main-nav a:hover:after {
  clip-path: inset(0);
}

.customer-logos {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  width: 100%;
  display: grid;
}

.customer-logos img {
  object-fit: contain;
  object-position: center;
  width: 100%;
}

.content {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 6rem 2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.content--first {
  padding-top: 12rem;
}

.content--hero {
  min-height: 100vh;
}

.content--twocol {
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 2rem;
  display: grid;
}

@media (width <= 800px) {
  .content--twocol {
    flex-direction: column;
    display: flex;
  }
}

.content--footer {
  grid-template-columns: 11.25rem 1fr 11.25rem;
  gap: 2rem;
  display: grid;
}

@media (width <= 680px) {
  .content--footer {
    text-align: center;
    flex-direction: column;
    display: flex;
  }
}

section {
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  display: flex;
}

.section--black {
  color: #fff;
  background-color: #000;
}

.section__title {
  margin: 0;
  font-size: 6rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1;
}

@media (width <= 520px) {
  .section__title {
    margin-bottom: .375rem;
    font-size: 3.75rem;
  }
}

.section__subtitle {
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1.5rem;
}

.section__text {
  margin-top: 13.5rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.5;
}

.section__text--closer {
  margin-top: 6rem;
}

@media (width <= 800px) {
  .section__text {
    margin-top: 0;
  }
}

.section__text p {
  margin: 0 0 1.5rem;
}

.section__text p:last-child {
  margin-bottom: 0;
}

.section__form {
  width: 100%;
}

.form {
  flex-direction: column;
  gap: 2.25rem;
  margin-top: 13.5rem;
  display: flex;
}

@media (width <= 800px) {
  .form {
    margin-top: 0;
  }
}

.form__label {
  border-bottom: 1px solid;
  flex-direction: column-reverse;
  padding-top: .75rem;
  display: flex;
}

.form__label__text {
  transform-origin: 0 100%;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1.5rem;
  transition: all .3s ease-out;
}

.form__label input, .form__label textarea {
  appearance: none;
  color: currentColor;
  background: none;
  border: 0;
  width: 100%;
  font-family: HelveticaNow, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.form__label input:focus, .form__label textarea:focus {
  outline: 0;
}

.form__label input:focus + *, .form__label textarea:focus + * {
  transform: scale(.875)translateY(-50%);
}

.form__label textarea {
  resize: vertical;
}

.form input[type="submit"] {
  appearance: none;
  cursor: pointer;
  background: none;
  border: 0;
  width: auto;
  margin-left: auto;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1.5rem;
}

.form__success {
  opacity: 0;
  color: #0c0;
  text-align: center;
  background: #efe;
  padding: 1.5rem 1rem;
  transition: all .3s ease-in-out;
  display: none;
}

.form__success--show {
  opacity: 1;
  display: block;
}

.form__error {
  opacity: 0;
  color: #c00;
  text-align: center;
  background: #fee;
  padding: 1.5rem 1rem;
  transition: all .3s ease-in-out;
  display: none;
}

.form__error--show {
  opacity: 1;
  display: block;
}

.form__submit {
  align-self: flex-end;
  display: inline-block;
  position: relative;
}

.form__submit:after {
  content: "";
  opacity: 0;
  border: 4px solid #000;
  border-top-color: #0000;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  margin-top: -.5rem;
  transition: opacity .3s ease-in-out;
  animation: .5s linear infinite both progress;
  display: block;
  position: absolute;
  top: 50%;
  right: -2rem;
}

.form__submit--progress:after {
  opacity: 1;
}

footer {
  color: #ffffff80;
  background-color: #000;
  padding: 3rem 0;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

footer a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease-out;
}

footer a:hover {
  color: #fff;
}

.footer__nav {
  flex-direction: column;
  gap: .75rem;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.footer__nav__title {
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 900;
  line-height: 1.875rem;
}

.footer__logo {
  justify-content: center;
  display: flex;
}

@media (width <= 680px) {
  .footer__logo {
    order: -1;
  }
}

.footer__logo img {
  width: 9rem;
  height: auto;
  margin: 0 auto;
}

/*# sourceMappingURL=index.ff7826a7.css.map */
